button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

* {
  margin: 0;
}

*, *::before, *::after {
  box-sizing: border-box;
}